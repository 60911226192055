import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ScanRes } from '../services/checkin';

const saveScannedUsersToSession = (scannedUsers: Array<ScanRes>) => {
  try {
    const serializedUsers = JSON.stringify(scannedUsers);
    sessionStorage.setItem('scannedUsers', serializedUsers);
  } catch (error) {
    console.error('Failed to save scanned users to sessionStorage', error);
  }
};

const loadScannedUsersFromSession = (): Array<ScanRes> => {
  try {
    const serializedUsers = sessionStorage.getItem('scannedUsers');
    if (serializedUsers === null) {
      return [];
    }
    return JSON.parse(serializedUsers);
  } catch (error) {
    console.error('Failed to load scanned users from sessionStorage', error);
    return [];
  }
};

type initialStateType = {
  scannedUsers: Array<ScanRes>;
};

const initialState: initialStateType = {
  scannedUsers: loadScannedUsersFromSession(),
};

export const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    addScanUser: (state, action: PayloadAction<ScanRes>) => {
      state.scannedUsers.push(action.payload);
      saveScannedUsersToSession(state.scannedUsers);
    },
    removeScanUser: (state, action: PayloadAction<{ id: number }>) => {
      state.scannedUsers = state.scannedUsers.filter(
        (p) => p.profile_id !== action.payload.id,
      );
      saveScannedUsersToSession(state.scannedUsers);
    },
  },
});

export const { addScanUser, removeScanUser } = checkinSlice.actions;

export default checkinSlice.reducer;
