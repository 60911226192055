import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import ky from 'ky';

export const packageApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/uaps/api/v1`,
    fetchFn: async (...args) => ky(...args),
    prepareHeaders: async (headers) => {
      try {
        const res = await fetchAuthSession();
        if (!res.userSub) throw new Error('Unauthenticated');
        const token = res.tokens?.accessToken?.toString();
        headers.set('Authorization', `Bearer ${token}`);
      } catch (e) {}
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPackageApi: builder.query({
      query: () => `/packages`,
    }),
    getPackageById: builder.query({
      query: (packageId) => `/packages/${packageId}`,
    }),
    updatePackage: builder.mutation({
      query: ({ packageId, payload }) => ({
        url: `/packages/${packageId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addPackage: builder.mutation({
      query: (payload) => ({
        url: `/packages`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),

  reducerPath: 'packageApi',
});

export const {
  useGetPackageApiQuery,
  useGetPackageByIdQuery,
  useAddPackageMutation,
  useUpdatePackageMutation,
} = packageApi;
