import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import type { FormikProps } from 'formik';
import { Formik, Form, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import FieldInput from '../../../components/FieldInput';
import {
  useAddPackageMutation,
  useUpdatePackageMutation,
} from '../../../services/package';

interface InitialValues {
  id?: string;
  packageName: string;
  packageDescription: string;
  minutes: string | number;
  price: string | number;
  mostPopular: boolean | string;
  highlights1: string;
  highlights2: string;
}

interface Properties {
  open: boolean;
  isEditMode?: boolean;
  data?: any;
  refetch: () => void;
  handleClose: () => void;
}

const validationSchema = Yup.object({
  packageName: Yup.string().required('Package Name is required'),
  // packageDescription: Yup.string().required('Description is required'),
  minutes: Yup.number()
    .required('Minutes are required')
    .positive('Minutes must be a positive number')
    .integer('Minutes must be an integer'),
  price: Yup.number()
    .required('Price is required')
    .positive('Price must be a positive number'),
  mostPopular: Yup.boolean(),
  highlights1: Yup.string(),
  highlights2: Yup.string(),
});

const AddPackage = ({
  open,
  handleClose,
  isEditMode,
  data,
  refetch,
}: Properties) => {
  const [initialValues, setInitialValues] = useState<InitialValues>({
    packageName: '',
    packageDescription: '',
    minutes: '',
    price: '',
    mostPopular: false,
    highlights1: '',
    highlights2: '',
  });
  const [successOpen, setSuccessOpen] = useState(false);
  const [description, setDescription] = useState(
    initialValues.packageDescription,
  );
  const packageRef = useRef<FormikProps<InitialValues>>(null);

  const [addPackage, { isLoading }] = useAddPackageMutation();
  const [updatePackage, { isLoading: isEditLoading }] =
    useUpdatePackageMutation();

  const onSubmit = (values: InitialValues) => {
    values.packageDescription = description;

    if (isEditMode) {
      updatePackage({ packageId: values.id, payload: values }).then(
        (data: any) => {
          if (!data.error) {
            refetch();
            setSuccessOpen(true);
            handleClose();
          }
        },
      );
    } else {
      addPackage(values).then((data: any) => {
        if (!data.error) {
          refetch();
          setSuccessOpen(true);
          handleClose();
        }
      });
    }
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleMostPopularChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (packageRef?.current?.values) {
      const {
        packageName,
        packageDescription,
        minutes,
        price,
        highlights1,
        highlights2,
        id,
      } = packageRef?.current?.values;
      setInitialValues(() => ({
        packageName,
        packageDescription,
        minutes,
        price,
        id,
        highlights1,
        highlights2,
        mostPopular: checked,
      }));
    } else {
      setInitialValues((prevValues) => ({
        ...prevValues,
        mostPopular: checked,
      }));
    }
  };

  useEffect(() => {
    if (isEditMode && data) {
      setInitialValues({ ...data });
      setDescription(data?.packageDescription);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setInitialValues({
        packageName: '',
        packageDescription: '',
        minutes: '',
        price: '',
        mostPopular: false,
        highlights1: '',
        highlights2: '',
      });
      setDescription('');
    };
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-3xl text-kc-grey font-bold">
          {!isEditMode ? 'Add New Package' : 'Update Package'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={packageRef}
            enableReinitialize
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-6 p-1">
                    <FieldInput
                      name="packageName"
                      label="Package Name"
                      placeholder=" Package Name"
                      type="text"
                      required={true}
                    />
                  </div>
                  <div className="col-span-6 p-1">
                    <FieldInput
                      name="minutes"
                      label="Minutes"
                      placeholder=" Minutes"
                      type="number"
                      required={true}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-6 p-1">
                    <FieldInput
                      name="price"
                      label="Price"
                      placeholder=" Price"
                      type="number"
                      required={true}
                    />
                  </div>
                  <div className="col-span-12 p-1">
                    <FieldInput
                      name="highlights1"
                      label="Highlight 1"
                      placeholder=" Highlight 1"
                      type="text"
                      required={true}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-2  p-1 mt-2">
                  <div className="col-span-12 p-1">
                    <FieldInput
                      name="highlights2"
                      label="Highlight 2"
                      placeholder=" Highlight 2"
                      type="text"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-12 p-1">
                    <FormLabel
                      sx={{
                        color: 'text.primary',
                        fontWeight: 600,
                        marginBottom: 1,
                        fontSize: '16px',
                        '& .MuiFormLabel-asterisk': { color: '#F39200' },
                        '&.Mui-focused': { color: '#000' },
                        '&.Mui-error': { color: '#d32f2f' },
                      }}
                    >
                      Description
                    </FormLabel>
                    <textarea
                      onChange={handleDescriptionChange}
                      value={description}
                      className="h-20  mt-1 text-black text-lg px-5 py-3 rounded-xl w-11/12"
                    ></textarea>
                    {/* {touched.packageDescription && description.length <= 0 && (
                      <span className="text-red-600">
                        Description is Required
                      </span>
                    )} */}
                  </div>
                </div>
                <div className="flex p-1">
                  <FormControlLabel
                    control={
                      <Field
                        name="mostPopular"
                        type="checkbox"
                        as={Checkbox}
                        checked={initialValues.mostPopular}
                        onChange={handleMostPopularChange}
                      />
                    }
                    label="Popular"
                  />
                </div>

                <Stack direction={'row'}>
                  <Box className="flex-1"></Box>
                  <Stack direction="row" gap={1}>
                    <Button
                      disableRipple
                      // disabled={isLoading}
                      onClick={handleClose}
                      className="normal-case rounded-[32px] bg-white text-kc-grey border-solid border-kc-primary border-2 font-semibold hover:bg-kc-white hover:text-kc-grey hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disableRipple
                      // disabled={isLoading}
                      className="normal-case rounded-[32px] bg-kc-primary text-white font-semibold hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
                    >
                      {isLoading || isEditLoading ? (
                        <CircularProgress className="text-white" />
                      ) : !isEditMode ? (
                        'Submit'
                      ) : (
                        'Update'
                      )}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog
        open={successOpen}
        onClose={() => {
          setSuccessOpen(false);
          handleClose();
        }}
      >
        <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3">
          <Box className="w-20 h-20 bg-kc-primary rounded-full flex items-center justify-center">
            <DoneAllIcon className="text-white text-5xl" />
          </Box>
          <Typography className="font-bold text-kc-grey text-2xl">
            {isEditMode ? 'Package Updated!' : 'Package Added'}
          </Typography>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddPackage;
