import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logo from '../../assets/logo.svg';
import { Input } from '../../components/Input';

import { NewPassword } from './components/NewPassword';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const Login: React.FC<{}> = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [credsInvalid, setCredsInvalid] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState('');
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const { nextStep, isSignedIn } = await signIn({
          username: values.email,
          password: values.password,
        });
        if (isSignedIn) {
          navigate('/');
        }
        if (
          nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          setStep(1);
        }
      } catch (e) {
        setCredsInvalid(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const onNewPassword = async (password: string) => {
    setIsLoading(true);
    try {
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: password,
      });
      if (isSignedIn) {
        navigate('/');
      }
    } catch (err) {
      if (err.code === 'InvalidPasswordException')
        setInvalidPassword(err.message);

      setIsLoading(false);
    }
  };

  return (
    <Box className="flex flex-col justify-center w-full items-center">
      <img className="relative w-28 h-24 top-2 mt-5" src={logo} alt="logo" />
      <Box className="flex justify-center w-full items-center flex-1">
        {step === 0 && (
          <Box className="border-solid border-[1px] border-kc-grey-4 flex flex-col gap-4 p-10 rounded-2xl h-fit w-[600px] items-center">
            <Typography className="text-4xl font-bold text-kc-grey">
              KidzCubicle Portal Login
            </Typography>
            <Box className="w-full">
              <form onSubmit={formik.handleSubmit}>
                <Input
                  name="email"
                  label="Email"
                  placeholder="xyz@gmail.com"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email &&
                    (Boolean(formik.errors.email) || credsInvalid)
                  }
                  errorMessage={formik.touched.email ? formik.errors.email : ''}
                />
                <Input
                  name="password"
                  label="Password"
                  placeholder="••••••••"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password &&
                    (Boolean(formik.errors.password) || credsInvalid)
                  }
                  errorMessage={
                    formik.touched.password ? formik.errors.password : ''
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Box className="flex mb-8">
                  <Box className="flex-1 text-base text-kc-red font-semibold">
                    {credsInvalid ? 'Invalid Credentials' : ''}
                  </Box>
                  <Link
                    href="/forgot-password"
                    underline="none"
                    className="text-base font-semibold text-black normal-case justify-end hover:opacity-70"
                  >
                    Forgot Password?
                  </Link>
                </Box>
                <Button
                  fullWidth
                  type="submit"
                  disableRipple
                  disabled={isLoading}
                  className="normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
                >
                  {isLoading && (
                    <CircularProgress
                      size={20}
                      className="text-kc-grey-4 mr-6"
                    />
                  )}
                  Sign in
                </Button>
              </form>
            </Box>
          </Box>
        )}
        {step === 1 && (
          <NewPassword
            onBack={() => setStep(0)}
            onNext={onNewPassword}
            isLoading={isLoading}
            invalidMessage={invalidPassword}
          />
        )}
      </Box>
      <Box className="w-full border-solid border-t border-b-0 border-kc-grey text-kc-grey p-2 text-center">
        © Copyright KidzCubicle | All Rights Reserved.
      </Box>
    </Box>
  );
};
