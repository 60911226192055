import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import ky from 'ky';

export type ScanRes = {
  profile_first_name: string;
  profile_last_name: string;
  profile_id: number;
  age: number;
  minutes: number;
  picture: string;
  barcode: string;
  alternate_emergency_no: string;
  first_name: string;
  last_name: string;
  account_id: number;
  phone_number: string;
  first_aid_info: string;
  sub_id: string;
};

export type ActiveIdleRes = Array<{
  id: number;
  barcode: string;
  profileId: number;
  accountId: number;
  availableMinutes: number;
  checkinTime: string;
  estimatedEndTime: string;
  sessionStatus: string;
  profileFirstName: string;
  profileLastName: string;
  dob: string;
  picture: string;
  firstAidInfo: string;
  minutes: number;
  alternateEmergencyNo: string;
  accountFirstName: string;
  accountLastName: string;
  phoneNumber: string;
}>;

export type CheckinSessionRes = {
  barcode: string;
  profileId: number;
  accountId: number;
  availableMinutes: number;
  checkinTime: string;
  estimatedEndTime: string;
  sessionStatus: string;
  staffId: number;
  redNotificationTrigger: string;
  amberNotificationTrigger: string;
};

export const checkinApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/cis/api/v1`,
    fetchFn: async (...args) => ky(...args),
    prepareHeaders: async (headers) => {
      try {
        const res = await fetchAuthSession();
        if (!res.userSub) throw new Error('Unauthenticated');
        const token = res.tokens?.accessToken?.toString();
        headers.set('Authorization', `Bearer ${token}`);
      } catch (e) {}
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getSessions: builder.query<ActiveIdleRes, void>({
      query: () => `/cis/checkin_sessions/new-active-and-idle`,
    }),

    getScanBarcode: builder.query<ScanRes, { barcode: string }>({
      query: ({ barcode }) => `/checkin-profile-details/${barcode}`,
    }),

    postUserCheckin: builder.mutation<
      CheckinSessionRes,
      {
        barcode: string;
        profileId: number;
        accountId: number;
        availableMinutes: number;
        checkinTime: string;
        staffId: number;
      }
    >({
      query: (payload) => ({
        url: `/cis/api/checkin_session`,
        method: 'POST',
        body: payload,
      }),
    }),

    postMoveUserToWaiting: builder.mutation<
      { message: string },
      { profileId: number; sessionStatus: string }
    >({
      query: (payload) => ({
        url: `/cis/api/waiting_area`,
        method: 'POST',
        body: payload,
      }),
    }),

    postUserCheckout: builder.mutation<
      { message: string },
      { barcode: string }
    >({
      query: (payload) => ({
        url: `/cis/api/checkout_session`,
        method: 'POST',
        body: payload,
      }),
    }),

    postStaffCheckout: builder.mutation<
      { message: string },
      { profileId: number; staffId: number }
    >({
      query: (payload) => ({
        url: `/cis/api/checkout_session/staff_auth`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),

  reducerPath: 'checkinApi',
});

export const {
  useGetSessionsQuery,
  useLazyGetSessionsQuery,
  useLazyGetScanBarcodeQuery,
  usePostUserCheckinMutation,
  usePostMoveUserToWaitingMutation,
  usePostUserCheckoutMutation,
  usePostStaffCheckoutMutation,
  useGetScanBarcodeQuery,
} = checkinApi;
