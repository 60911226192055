import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import ky from 'ky';

export const promoCodeApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/uaps/api/v1`,
    fetchFn: async (...args) => ky(...args),
    prepareHeaders: async (headers) => {
      try {
        const res = await fetchAuthSession();
        if (!res.userSub) throw new Error('Unauthenticated');
        const token = res.tokens?.accessToken?.toString();
        headers.set('Authorization', `Bearer ${token}`);
      } catch (e) {}
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPromoCodeList: builder.query({
      query: () => `/promo-codes/all`,
    }),

    updatePromoCode: builder.mutation({
      query: ({ promoCodeId, payload }) => ({
        url: `/promo-codes/update/${promoCodeId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addPromoCode: builder.mutation({
      query: (payload) => ({
        url: `/promo-codes/add`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),

  reducerPath: 'promoCodeApi',
});

export const {
  useGetPromoCodeListQuery,
  useAddPromoCodeMutation,
  useUpdatePromoCodeMutation,
} = promoCodeApi;
